import { mdiCurrencyEur, mdiDomain, mdiMapMarkerOutline, mdiShapeOutline, mdiTimerOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
// import devDebug from "../../../Helpers/debug";
import { useViewEditTenderStore } from "../../../Stores/viewEditTender";
import FavouriteTender from "./favouriteTender";
import dayjs from "dayjs";
import { returnTenderTitle } from "../../View Tender/SummaryTab/modals/SummaryResultsModal/summaryResultModal";
import default_mapdata from "../../../Assets/Mapdata/default_mapdata.json";

const TenderListItemNormal = ({ tender, bookmarked, disliked, summary }) => {
  const id = tender.id ? tender.id : tender.tender_id;
  const [showProducts, setShowProducts] = React.useState(null);
  const [showRerankedProducts, setShowRerankedProducts] = React.useState(null);
  const [showLLMProducts, setShowLLMProducts] = React.useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [setSummaryIDToGET, setSummaryNameToGET, setSummaryResultModalVisible] = useViewEditTenderStore((state) => [
    state.setSummaryIDToGET,
    state.setSummaryNameToGET,
    state.setSummaryResultModalVisible,
  ]);

  // const debugProducts = [
  //   "Strumentario / Strumenti",
  //   "Sonde",
  //   "another long product name",
  //   "and one more very long product name",
  //   "Sonde",
  //   "Strumentario / Strumenti",
  //   "Sonde",
  //   "another long product name",
  //   "and one more very long product name",
  // ];

  return (
    <div className="d-flex p-4 p-sm-5 tc-tender-search-list-item tc-flex-gap-20px position-relative">
      <div className="position-absolute tc-favourite-star-position">
        <FavouriteTender bookmarked={bookmarked} disliked={disliked} id={id} />
      </div>
      <div className="w-full d-flex tc-flex-gap-20px tc-large-invisible mt-2">
        <div className="flex-column tc-flex-gap-10px p-1 tc-large-invisible">
          <a href={`/view_tender/${id}`} target="_blank" rel="noreferrer">
            <Button className="tc-rounded-btn py-2 px-4 d-none d-sm-block fw-bold" color="success">
              {t("View Tender")}
            </Button>
          </a>

          {tender?.attachment?.length === 0 ? null : (
            <div
              onClick={() => {
                if (summary.state === "COMPLETED") {
                  setSummaryIDToGET(summary.id);
                  setSummaryNameToGET(summary.name);
                  setSummaryResultModalVisible(true);
                }
              }}
              className={`tc-large-invisible tc-flex-gap-5px align-items-center ${
                summary.state === "COMPLETED" ? "cursor-pointer text-decoration-underline" : ""
              } `}
            >
              <a
                className="tc-text-muted text-decoration-underline"
                target="_blank"
                rel="noreferrer"
                href={`/view_tender/${id}#request`}
              >{`${t("Summary")}: `}</a>
              {summary.state === "COMPLETED" ? (
                <div className="tc-green-circle"></div>
              ) : summary.state === "IN_PROGRESS" || summary.state === "REQUIRES_MANUAL_COMPLETION" ? (
                <div className="tc-yellow-circle"></div>
              ) : summary.state === "ERROR" ? (
                <div className="tc-red-circle"></div>
              ) : (
                <div className="tc-grey-circle"></div>
              )}
            </div>
          )}
        </div>
        <div
          onClick={() => navigate(`/view_tender/${id}`)}
          className="d-flex flex-column tc-flex-gap-20px cursor-pointer pt-4 pt-sm-0 w-full text-center text-sm-start"
        >
          <div className="d-flex tc-flex-gap-5px align-items-center tc-word-break-break-all text-start">
            <Icon className="tc-tender-search-list-item-icon-size" path={mdiDomain} size={1} />
            {/* below, tendering_authority.name is used when results are coming from tenders/ids= endpoint, whereas search returns tendering_authority as string */}
            {
              <span>
                {tender?.tendering_authority?.name ? tender.tendering_authority.name : tender.tendering_authority}
              </span>
            }
          </div>

          <div className="fw-bold">{`${returnTenderTitle(tender, i18n.language.slice(0, 2))}`}</div>
          <div className="d-flex flex-column flex-lg-row tc-flex-gap-10px align-items-start align-items-lg-center ">
            <div className="d-flex flex-column flex-sm-row tc-flex-gap-10px align-items-start align-items-sm-center">
              <div className="d-flex tc-flex-gap-5px align-items-center tc-tender-search-list-item-fixed-width-150px">
                <Icon className="tc-tender-search-list-item-icon-size" path={mdiCurrencyEur} size={1} />
                {
                  <span>
                    {tender?.estimated_total_value
                      ? `${parseFloat(tender?.estimated_total_value).toLocaleString(i18n.language.slice(0, 2))}`
                      : t("Value unknown")}
                  </span>
                }
              </div>
              <div className="d-none d-sm-block">|</div>
              <div className="d-flex tc-flex-gap-5px align-items-center tc-tender-search-list-item-fixed-width-150px lg-full-width text-start">
                <Icon className="tc-tender-search-list-item-icon-size" path={mdiMapMarkerOutline} size={1} />
                {default_mapdata?.[tender?.nut_code]?.name}
              </div>
            </div>
            <div className="d-none d-lg-block">|</div>
            <div className="d-flex flex-column flex-sm-row tc-flex-gap-5px align-items-start align-items-sm-center">
              <div className="d-flex tc-flex-gap-5px align-items-center tc-tender-search-list-item-fixed-width-150px">
                <Icon className="tc-tender-search-list-item-icon-size" path={mdiTimerOutline} size={1} />
                {dayjs(tender.closing_date).format("DD/MM/YYYY")}
              </div>

              {Array.isArray(tender.soa_categories) && tender.soa_categories.length > 0 ? (
                <React.Fragment>
                  <div className="d-none d-sm-block">|</div>
                  <div className="d-flex tc-flex-gap-5px align-items-center tc-word-break-break-all text-start">
                    <Icon className="tc-tender-search-list-item-icon-size" path={mdiShapeOutline} size={1} />

                    {tender.soa_categories.join(", ")}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <Button
            onClick={() => navigate(`/view_tender/${id}`)}
            className="tc-rounded-btn py-2 px-4 tc-large-invisible-inverted w-full"
            color="success"
          >
            {t("View Tender")}
          </Button>
          {tender?.attachment?.length === 0 ? null : (
            <div
              onClick={() => {
                if (summary.state === "COMPLETED") {
                  setSummaryIDToGET(summary.id);
                  setSummaryNameToGET(summary.name);
                  setSummaryResultModalVisible(true);
                }
              }}
              className={`tc-large-invisible-inverted tc-flex-gap-5px align-items-center ${
                summary.state === "COMPLETED" ? "cursor-pointer text-decoration-underline" : ""
              } `}
            >
              <div>{`${t("Summary")}: `}</div>
              {summary.state === "NONE" ? (
                <div className="tc-grey-circle"></div>
              ) : summary.state === "IN_PROGRESS" ? (
                <div className="tc-yellow-circle"></div>
              ) : summary.state === "ERROR" ? (
                <div className="tc-red-circle"></div>
              ) : (
                <div className="tc-green-circle"></div>
              )}
            </div>
          )}

          <div className="d-flex flex-column tc-flex-gap-5px">
            {tender?.products && tender.products.length > 0 ? (
              <div className="d-flex align-items-center tc-flex-gap-10px position-relative ">
                <div
                  className="fw-bold"
                  onMouseEnter={(e) => {
                    setShowProducts(true);
                  }}
                  onMouseLeave={(e) => {
                    setShowProducts(false);
                  }}
                >{`${t("Products")}: `}</div>
                {tender.products.length}

                <div className={` position-absolute tc-tender-item-products-popup ${showProducts ? "visible" : ""}`}>
                  <ul>
                    {tender?.products.map((product) => (
                      <li>{`${product}`}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}

            {tender?.products_reranked && tender.products_reranked.length > 0 ? (
              <div
                className="d-flex align-items-center tc-flex-gap-10px position-relative"
                onMouseEnter={() => {
                  setShowRerankedProducts(true);
                }}
                onMouseLeave={() => {
                  setShowRerankedProducts(false);
                }}
              >
                <div className="fw-bold">{`${t("Re-ranked Products")}: `}</div>
                {tender.products_reranked.length}

                <div
                  className={` position-absolute tc-tender-item-products-popup ${
                    showRerankedProducts ? "visible" : ""
                  }`}
                >
                  <ul>
                    {tender?.products_reranked.map((product) => (
                      <li>{`${product}`}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}
            {tender?.llm_products && tender.llm_products.length > 0 ? (
              <div className="d-flex align-items-center tc-flex-gap-10px position-relative ">
                <div
                  className="fw-bold"
                  onMouseEnter={() => {
                    setShowLLMProducts(true);
                  }}
                  onMouseLeave={() => {
                    setShowLLMProducts(false);
                  }}
                >{`${t("LLM Products")}: `}</div>
                {tender?.llm_products.length}

                <div className={` position-absolute tc-tender-item-products-popup ${showLLMProducts ? "visible" : ""}`}>
                  <ul>
                    {tender?.llm_products.map((product) => (
                      <li>{`${product}`}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
          {tender.national_id ? (
            <div className="d-flex align-items-center tc-flex-gap-10px">
              <div className="fw-bold">{`${t("CIG")}: `}</div>
              {tender.national_id}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TenderListItemNormal;
