import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";
import { immer } from "zustand/middleware/immer";
// import devDebug from "../Helpers/debug";
// import { current } from "immer";
// import cloneDeep from "lodash/cloneDeep";

// define the initial state
const initialState = {
  _hasHydrated: false,
  page: 1,
  pageSize: 10,
  totalPages: null,
  pageInput: false,
  vademecums: [],
  ordering: { id: "", desc: undefined, url: "" },
  user_email: "",
  state: "REQUIRES_MANUAL_COMPLETION",
  created_at_after: "",
  created_at_before: "",
  completed_at_after: "",
  completed_at_before: "",
  vademecumStatus: "loading",
  hiddenColumns: [],
  reRenderInputs: Math.random(),
  createOrEditMode: "create",
  templateToBeEdited: {
    id: null,
    name: "",
    user: null,
    fields: [],
  },
  selectedTemplateFields: [],
  templateName: "",
  selectedTemplate: null,
  refreshVademecumsInterval: null,
  individualFieldStatus: {},
  emailUserModalVisible: false,
  emailSubject: "",
  emailBody: "",
  uploads: [],
  regenStatus: {},
  XLSXData: "",
  regenInterval: null,
  vademecumReceivedModalVisible: false,
  instance: null,
  refreshpdf: Math.random(),
  queryHistoryCallTimestamp: null,
  showQueryHistoryItems: {},
  queryHistoryItemLogStatus: "loading",
  queryHistoryItemLogError: "",
  queryHistoryItemLog: [],
  queryHistoryItemLogToBeShown: null,
  queryHistoryItemLogModalVisible: false,
  queryHistoryItemLogName: false,
  updateInstance: () => { },
};

export const useVademecumStore = createWithEqualityFn(
  immer((set, get) => ({
    ...initialState,
    setHasHydrated: (state) => {
      set({
        _hasHydrated: state,
      });
    },
    setQueryHistoryItemLogName: (queryHistoryItemLogName) =>
      set((state) => {
        state.queryHistoryItemLogName = queryHistoryItemLogName;
      }),
    setQueryHistoryItemLogError: (queryHistoryItemLogError) =>
      set((state) => {
        state.queryHistoryItemLogError = queryHistoryItemLogError;
      }),

    setQueryHistoryItemLog: (queryHistoryItemLog) =>
      set((state) => {
        state.queryHistoryItemLog = queryHistoryItemLog;
      }),
    setQueryHistoryItemLogStatus: (queryHistoryItemLogStatus) =>
      set((state) => {
        state.queryHistoryItemLogStatus = queryHistoryItemLogStatus;
      }),
    setQueryHistoryItemLogToBeShown: (queryHistoryItemLogToBeShown) =>
      set((state) => {
        state.queryHistoryItemLogToBeShown = queryHistoryItemLogToBeShown;
      }),
    setQueryHistoryItemLogModalVisible: (queryHistoryItemLogModalVisible) =>
      set((state) => {
        state.queryHistoryItemLogModalVisible = queryHistoryItemLogModalVisible;
      }),
    setQueryHistoryItemsStatus: (vademecum, queryKey, status) =>
      set((state) => {
        if (state?.showQueryHistoryItems?.[vademecum]?.[queryKey]) {
          state.showQueryHistoryItems[vademecum][queryKey].status = status;
        } else {
          state.showQueryHistoryItems[vademecum][queryKey] = { show: true, status: status, items: [] };
        }
      }),
    setShowQueryHistoryItems: (vademecum, queryKey) =>
      set((state) => {
        if (state?.showQueryHistoryItems?.[vademecum]?.[queryKey]) {
          state.showQueryHistoryItems[vademecum][queryKey].show = state?.showQueryHistoryItems?.[vademecum]?.[queryKey]
            .show
            ? false
            : true;
        } else {
          state.showQueryHistoryItems[vademecum] = {};
          state.showQueryHistoryItems[vademecum][queryKey] = {
            show: state?.showQueryHistoryItems?.[vademecum]?.[queryKey] ? false : true,
            status: "loading",
            items: [],
          };
        }
      }),
    setQueryHistoryCallTimestamp: (queryHistoryCallTimestamp) =>
      set((state) => {
        state.queryHistoryCallTimestamp = queryHistoryCallTimestamp;
      }),
    setQueryHistory: (vademecum, queryKey, items) =>
      set((state) => {
        state.showQueryHistoryItems[vademecum][queryKey].items = items;
      }),
    setInstanceAndUpdateInstance: (instance, updateInstance) =>
      set((state) => {
        state.instance = instance;
        state.updateInstance = updateInstance;
      }),
    refreshPDF: () =>
      set((state) => {
        state.refreshpdf = Math.random();
      }),
    setUsersAssignedToTemplate: (usersAssignedToTemplate) =>
      set((state) => {
        state.templateToBeEdited.users = usersAssignedToTemplate.map((user) => user.value);
      }),
    setVademecumReceivedModalVisible: (vademecumReceivedModalVisible) =>
      set((state) => {
        state.vademecumReceivedModalVisible = vademecumReceivedModalVisible;
      }),
    setRegenStatus: (id, status) =>
      set((state) => {
        state.regenStatus[id] = status;
      }),
    setXLSXData: (XLSXData) =>
      set((state) => {
        state.XLSXData = XLSXData;
      }),
    setRegenInterval: (interval) =>
      set((state) => {
        state.regenInterval = interval;
      }),
    setUploads: (uploads) =>
      set((state) => {
        state.uploads = uploads;
      }),
    addUploads: (uploads) =>
      set((state) => {
        if (uploads.length) {
          for (let upload of uploads) {
            state.uploads.push(upload);
          }
        } else {
          state.uploads.push(uploads);
        }
      }),
    clearEmptyUploads: () =>
      set((state) => {
        state.uploads = state.uploads.filter((upload) => Object.keys(upload).length === 0);
      }),
    removeUpload: (name) =>
      set((state) => {
        state.uploads = state.uploads.filter((u) => u.name !== name);
      }),
    setIndividualFieldStatus: (id, status) =>
      set((state) => {
        state.individualFieldStatus[id] = status;
      }),

    setEmailUserModalVisible: (emailUserModalVisible) =>
      set((state) => {
        state.emailUserModalVisible = emailUserModalVisible;
      }),

    setEmailSubject: (emailSubject) =>
      set((state) => {
        state.emailSubject = emailSubject;
      }),
    setEmailBody: (emailBody) =>
      set((state) => {
        state.emailBody = emailBody;
      }),
    setRefreshVademecumsInterval: (refreshVademecumsInterval) =>
      set((state) => {
        state.refreshVademecumsInterval = refreshVademecumsInterval;
      }),
    setSelectedTemplate: (selectedTemplate) =>
      set((state) => {
        state.selectedTemplate = selectedTemplate;
      }),
    setSelectedTemplateFields: (selectedTemplateFields) =>
      set((state) => {
        state.templateToBeEdited.fields = selectedTemplateFields;
      }),
    setTemplateName: (templateName) =>
      set((state) => {
        state.templateToBeEdited.name = templateName;
      }),
    setTemplateToBeEdited: (templateToBeEdited) =>
      set((state) => {
        state.templateToBeEdited = templateToBeEdited;
      }),
    setCreateOrEditMode: (createOrEditMode) =>
      set((state) => {
        state.createOrEditMode = createOrEditMode;
      }),
    setPage: (payload) =>
      set((state) => {
        state.page = payload;
      }),
    setUserEmail: (user_email) =>
      set((state) => {
        state.user_email = user_email;
      }),
    setState: (val) =>
      set((state) => {
        state.state = val;
      }),
    setPageSize: (payload) =>
      set((state) => {
        state.pageSize = payload;
      }),
    setTotalPages: (payload) =>
      set((state) => {
        state.totalPages = payload;
      }),
    setPageInput: (payload) =>
      set((state) => {
        state.pageInput = payload;
      }),
    setVademecums: (vademecums) =>
      set((state) => {
        state.vademecums = vademecums;
      }),
    setOrdering: (ordering) =>
      set((state) => {
        state.ordering = ordering;
      }),
    setCreatedAtAfter: (created_at_after) =>
      set((state) => {
        state.created_at_after = created_at_after;
      }),
    setCreatedAtBefore: (created_at_before) =>
      set((state) => {
        state.created_at_before = created_at_before;
      }),
    setCompletedAtAfter: (completed_at_after) =>
      set((state) => {
        state.completed_at_after = completed_at_after;
      }),
    setCompletedAtBefore: (completed_at_before) =>
      set((state) => {
        state.completed_at_before = completed_at_before;
      }),
    setVademecumStatus: (vademecumStatus) =>
      set((state) => {
        state.vademecumStatus = vademecumStatus;
      }),
    setReRenderInputs: () =>
      set((state) => {
        state.reRenderInputs = Math.random();
      }),
    setHiddenColumns: (col) =>
      set((state) => {
        if (!state.hiddenColumns.includes(col.name)) {
          if (col.checked) {
            state.hiddenColumns.push(col.name);
          }
        } else {
          if (!col.checked) {
            state.hiddenColumns = state.hiddenColumns.filter((c) => c !== col.name);
          }
        }
      }),
    resetState: () => {
      set(initialState);
    },
  })),
  shallow
);
