import React from "react";
import "handsontable/dist/handsontable.full.min.css";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import devDebug from "../../../../../Helpers/debug";
import cloneDeep from "lodash/cloneDeep";
import NormalUserTable from "./normalUserTable";
import { ErrorBoundary } from "react-error-boundary";
import { getQueryHistory, regenerateField, saveField } from "./Utility/utility";
import { useAuth } from "../../../../../Services/auth";
import { useVademecumStore } from "../../../../../Stores/vademecumStore";
import { useViewEditTenderStore } from "../../../../../Stores/viewEditTender";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiCheck, mdiExclamation, mdiHistory, mdiRefresh } from "@mdi/js";
import { QueryHistoryItem } from "./Components/queryHistoryItem";

registerAllModules();

export default function SummaryLotsTable({ field, editable, adminSwitch }) {
  const containerRef = React.useRef(null);

  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const [data, setData] = React.useState([]);
  const [rawData, setRawData] = React.useState(null);
  const [columns, setColumns] = React.useState(null);
  const [height, setHeight] = React.useState("auto");

  const [summary, summaryIDToGET] = useViewEditTenderStore((state) => [state.summary, state.summaryIDToGET]);

  const [regenStatus, showQueryHistoryItems, setShowQueryHistoryItems] = useVademecumStore((state) => [
    state.regenStatus,
    state.showQueryHistoryItems,
    state.setShowQueryHistoryItems,
  ]);

  React.useEffect(() => {
    let finalData = [];
    let maxLength = 0;
    for (let row of field.value.split("\n")) {
      let rowLength = row.split("|").length;
      if (rowLength > maxLength) {
        maxLength = rowLength;
      }
    }

    for (let i = 0; i < field.value.split("\n").length; i++) {
      finalData.push(Array(maxLength).fill(""));
    }

    for (let i = 0; i < field.value.split("\n").length; i++) {
      for (let j = 0; j < field.value.split("\n")[i].split("|").length; j++) {
        const val = field.value.split("\n")[i].split("|")[j];
        finalData[i][j] = i === 0 ? val.toUpperCase().replace(/_/g, " ") : val;
      }
    }

    let placeholderSwapData = cloneDeep(finalData);
    let len = placeholderSwapData[0][placeholderSwapData[0].length - 1].length;
    let str = "";
    for (let i = 0; i < len; i++) {
      str += "0";
    }
    placeholderSwapData[0][placeholderSwapData[0].length - 1] = str;
    if (finalData[0].length > 1) {
      const rawData = makeRawData(finalData);
      setRawData(rawData[0]);
      setColumns(makeColumns(rawData[1]));
    } else {
      devDebug("There was no need to display a CSV table, as only the first row exists");
    }

    setData(placeholderSwapData);
    window.setTimeout(() => {
      setData(finalData);
    }, [10]);
  }, [field.value]);

  const hotRef = React.useRef(null);

  return (
    <ErrorBoundary>
      {editable ? (
        <div className="d-flex align-items-center tc-flex-gap-10px pe-2">
          <div className="d-flex flex-column align-items-center mb-2 w-full">
            <div className="d-flex flex-column align-items-start justify-content-start me-auto">
              <div className="d-flex tc-flex-gap-5px align-items-center">
                <div className="fw-bold me-auto">
                  {`${
                    field.field.translations[i18n.language.slice(0, 2)]
                      ? field.field.translations[i18n.language.slice(0, 2)].display_name
                      : field.field.translations[Object.keys(field.field.translations)[0]].display_name
                  } `}
                </div>

                {field?.source === "tenderchain" ? (
                  <Icon
                    path={mdiHistory}
                    className="tc_grow_on_hover_icon cursor-pointer tc-20px-icon-size"
                    title={t("View Query History Items")}
                    size={1}
                    onClick={() => {
                      setShowQueryHistoryItems(field?.vademecum, field?.field?.ai_checked_field_id);
                      getQueryHistory(auth, summary.tender, field?.field?.ai_checked_field_id, summaryIDToGET);
                    }}
                  />
                ) : null}
              </div>
              {showQueryHistoryItems?.[field?.vademecum]?.[field?.field?.ai_checked_field_id]?.show ? (
                showQueryHistoryItems?.[field?.vademecum]?.[field?.field?.ai_checked_field_id]?.status === "loading" ? (
                  <div className="tc-spinner-small"></div> //TODO: change data structure to include the items below
                ) : (
                  <div className="d-flex flex-column tc-cpv-list-max-height-322px mt-2 mb-2 border border-2 border-primary rounded p-3 tc-flex-gap-10px">
                    {showQueryHistoryItems?.[field?.vademecum]?.[field?.field?.ai_checked_field_id]?.items.map(
                      (item) => (
                        <QueryHistoryItem key={item.id} item={item} />
                      )
                    )}
                  </div>
                )
              ) : null}
            </div>
            <div ref={containerRef} style={{ height: height }} className={`w-full overflow-auto`}>
              <HotTable
                key={data}
                ref={hotRef}
                width="99%"
                height="auto"
                data={data}
                readOnly={false}
                rowHeaders={true}
                colHeaders={true}
                autoWrapRow={true}
                autoWrapCol={true}
                manualColumnMove={true}
                manualRowMove={true}
                manualColumnResize={true}
                manualRowResize={true}
                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                afterChange={(changes, source) => {
                  if (changes && source === "edit") {
                    setHeight(containerRef.current.clientHeight + "px");
                    updateLotsTable(hotRef, field, auth);
                    window.setTimeout(() => {
                      setHeight("auto");
                    }, 1500);
                  }
                }}
                afterColumnMove={() => {
                  setHeight(containerRef.current.clientHeight + "px");
                  window.setTimeout(() => {
                    setHeight("auto");
                  }, 1500);
                  updateLotsTable(hotRef, field, auth);
                }}
                afterRowMove={() => {
                  setHeight(containerRef.current.clientHeight + "px");
                  window.setTimeout(() => {
                    setHeight("auto");
                  }, 1500);
                  updateLotsTable(hotRef, field, auth);
                }}
                afterRemoveCol={() => {
                  setHeight(containerRef.current.clientHeight + "px");
                  window.setTimeout(() => {
                    setHeight("auto");
                  }, 1500);
                  updateLotsTable(hotRef, field, auth);
                }}
                afterRemoveRow={() => {
                  setHeight(containerRef.current.clientHeight + "px");
                  window.setTimeout(() => {
                    setHeight("auto");
                  }, 1500);
                  updateLotsTable(hotRef, field, auth);
                }}
                afterCreateCol={() => {
                  setHeight(containerRef.current.clientHeight + "px");
                  window.setTimeout(() => {
                    setHeight("auto");
                  }, 1500);
                  updateLotsTable(hotRef, field, auth);
                }}
                afterCreateRow={() => {
                  setHeight(containerRef.current.clientHeight + "px");
                  window.setTimeout(() => {
                    setHeight("auto");
                  }, 1500);
                  updateLotsTable(hotRef, field, auth);
                }}
                contextMenu={editable}
              />
            </div>
          </div>

          <Icon
            path={
              regenStatus?.[field.id]?.status === "error"
                ? mdiExclamation
                : regenStatus?.[field.id]?.status === "success"
                ? mdiCheck
                : mdiRefresh
            }
            size={1.2}
            className={`${
              regenStatus?.[field.id]?.status === "IN_PROGRESS" ? "rotate text-primary" : ""
            } tc-text-muted cursor-pointer`}
            onClick={() => regenerateField(field.id, auth)}
          />
        </div>
      ) : columns && rawData ? (
        <div className="d-flex align-items-center tc-flex-gap-10px">
          <div className="d-flex flex-column align-items-center mb-2 w-full">
            <div className="fw-bold me-auto">
              {`${
                field.field.translations[i18n.language.slice(0, 2)]
                  ? field.field.translations[i18n.language.slice(0, 2)].display_name
                  : field.field.translations[Object.keys(field.field.translations)[0]].display_name
              } `}
            </div>
            <NormalUserTable key={field.value} rawData={rawData} columns={columns} />
          </div>
        </div>
      ) : null}
    </ErrorBoundary>
  );
}

const getTranslatedDisplayName = (field, lang) => {
  return `${
    field.translations[lang]
      ? field.translations[lang].display_name
      : field.translations[Object.keys(field.translations)[0]].display_name
  } `;
};

const updateLotsTable = (hotRef, field, auth) => {
  const summaryIDToGET = useViewEditTenderStore.getState().summaryIDToGET;
  if (hotRef.current) {
    const hot = hotRef.current.hotInstance;
    let data = hot
      .getData()
      .map((arr) => arr.join("|"))
      .join("\n");

    let newField = { ...field };
    newField.value = data;
    saveField(summaryIDToGET, newField, auth);
  }
};

const makeRawData = (newData) => {
  const rawData = [];

  const columns = newData[0].map((item) => item.toLowerCase().replace(/ /g, "_"));

  for (let i = 1; i < newData.length; i++) {
    let tableItem = {};
    for (let j = 0; j < newData[i].length; j++) {
      tableItem[newData[0][j].toLowerCase().replace(/ /g, "_")] =
        newData[i][j][0] === '"' && newData[i][j][newData[i][j].length - 1] === '"'
          ? newData[i][j].substring(1, newData[i][j].length - 1)
          : newData[i][j];
    }
    rawData.push(tableItem);
  }

  return [rawData, columns];
};

const makeColumns = (columns) => {
  const cols = columns.map((col) => ({
    accessorKey: col,
    header: col.toUpperCase().replace(/_/g, " "),
    footer: (props) => props.column.id,
  }));

  return cols;
};

export const createXLSXData = () => {
  const summaryFieldValues = useViewEditTenderStore.getState().summaryFieldValues;
  const setXLSXData = useVademecumStore.getState().setXLSXData;

  let len = 10;
  for (let i = 0; i < summaryFieldValues.length; i++) {
    if (summaryFieldValues[i].return_type === "CSV") {
      let newLen = summaryFieldValues[i].value.split("\n")[0].split("|").length;
      if (newLen > len) {
        len = newLen;
      }
    }
  }

  let nonLotsVals = [];
  let lotsVals = [];

  for (let i = 0; i < summaryFieldValues.length; i++) {
    if (summaryFieldValues[i].return_type === "CSV") continue;
    let arr = Array(len).fill("");
    arr[0] = getTranslatedDisplayName(summaryFieldValues[i].field);
    arr[1] = summaryFieldValues[i].value;
    nonLotsVals.push(arr);
  }

  for (let i = 0; i < summaryFieldValues.length; i++) {
    if (summaryFieldValues[i].return_type === "CSV") {
      if (summaryFieldValues[i].value.split("\n").length > 1) {
        if (
          summaryFieldValues[i].value.split("\n")[0].split("|").length ===
          summaryFieldValues[i].value.split("\n")[1].split("|").length
        ) {
          for (let row of summaryFieldValues[i].value.split("\n")) {
            let arr = Array(len).fill("");
            for (let j = 0; j < row.split("|").length; j++) {
              arr[j] = row.split("|")[j]; //arr[j + Math.floor(len / 2 / 2)] = row.split("|")[j]; to centre table in XLSXDownloadButton
            }
            lotsVals.push(arr);
          }
        }
      }
    }
  }

  let xlsxData = { nonLotsVals: nonLotsVals };

  if (lotsVals.length > 0) {
    xlsxData.lotsVals = lotsVals;
  }

  setXLSXData(xlsxData);
};
