import { mdiArrowAll, mdiCheck, mdiExclamation, mdiHistory, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { TextareaAutosize } from "@mui/material";
import { useTranslation } from "react-i18next";
import { defaultTender, useViewEditTenderStore } from "../../../../../../Stores/viewEditTender";
import { useVademecumStore } from "../../../../../../Stores/vademecumStore";
import { getQueryHistory, regenerateField, returnStatus, saveField } from "../Utility/utility";
import { useAuth } from "../../../../../../Services/auth";
import React from "react";
import { QueryHistoryItem } from "./queryHistoryItem";

export default function FieldValueListItemAdmin({ field }) {
  const auth = useAuth();

  const { t, i18n } = useTranslation();

  const [summary, summaryIDToGET, summaryFieldValuesStatus] = useViewEditTenderStore((state) => [
    state.summary,
    state.summaryIDToGET,
    state.summaryFieldValuesStatus,
  ]);

  const [individualFieldStatus, regenStatus, showQueryHistoryItems, setShowQueryHistoryItems] = useVademecumStore(
    (state) => [
      state.individualFieldStatus?.[`${field.id}`],
      state?.regenStatus?.[`${field.id}`]?.status,
      state.showQueryHistoryItems,
      state.setShowQueryHistoryItems,
    ]
  );

  return (
    <div key={JSON.stringify(regenStatus)} className="d-flex align-items-center tc-flex-gap-10px">
      <Icon
        path={mdiArrowAll}
        className="tc-move-summary-field-arrows tc_grow_on_hover_icon cursor-pointer"
        title={t("Move field up or down")}
        size={0.8}
      />
      <div className="d-flex flex-column mb-2 w-full pe-2">
        <div className="d-flex tc-flex-gap-5px align-items-start justify-content-start">
          <div className="fw-bold">
            {`${
              field.field.translations[i18n.language.slice(0, 2)]
                ? field.field.translations[i18n.language.slice(0, 2)].display_name
                : field.field.translations[Object.keys(field.field.translations)[0]].display_name
            } `}
          </div>
          {field?.source === "tenderchain" ? (
            <Icon
              path={mdiHistory}
              className="tc_grow_on_hover_icon cursor-pointer tc-20px-icon-size"
              title={t("View Query History Items")}
              size={1}
              onClick={() => {
                setShowQueryHistoryItems(field?.vademecum, field?.field?.ai_checked_field_id);
                getQueryHistory(auth, summary.tender, field?.field?.ai_checked_field_id, summaryIDToGET);
              }}
            />
          ) : null}

          <div key={field.state} className="">{`(${returnStatus(regenStatus)})`}</div>
          {field.error ? <div className="tc-formerror-colour-only">{` - ${field.error}`}</div> : null}
        </div>

        {showQueryHistoryItems?.[field?.vademecum]?.[field?.field?.ai_checked_field_id]?.show ? (
          showQueryHistoryItems?.[field?.vademecum]?.[field?.field?.ai_checked_field_id]?.status === "loading" ? (
            <div className="tc-spinner-small"></div> //TODO: change data structure to include the items below
          ) : (
            <div className="d-flex flex-column tc-cpv-list-max-height-322px mt-2 mb-2 border border-2 border-primary rounded p-3 tc-flex-gap-10px">
              {showQueryHistoryItems?.[field?.vademecum]?.[field?.field?.ai_checked_field_id]?.items.map((item) => (
                <QueryHistoryItem key={item.id} item={item} />
              ))}
            </div>
          )
        ) : null}

        <div className="d-flex align-items-center w-full tc-flex-gap-5px position-relative">
          <div
            className={`position-absolute tc-summary-field-loading ${
              individualFieldStatus === "saving" ? "visibleFade" : "hiddenFade"
            }`}
          >
            {t("Saving...")} <div className="tc-spinner-small "></div>
          </div>

          <TextareaAutosize
            key={`${field.value}_${regenStatus}`}
            className={`form-control h-full ${
              individualFieldStatus !== "saving" && individualFieldStatus !== "saved" && field.state === "COMPLETED"
                ? "tc-text-area-green-outline"
                : ""
            } ${
              summaryFieldValuesStatus === "saving" || Object.keys(defaultTender).includes(field.field.name)
                ? "disabled"
                : ""
            } ${
              individualFieldStatus === "saving"
                ? "tc-text-area-yellow-outline"
                : individualFieldStatus && individualFieldStatus === "saved"
                ? "tc-text-area-green-outline"
                : ""
            }`}
            onBlur={(e) => {
              saveField(
                summaryIDToGET,
                {
                  id: field.id,
                  value: e.target.value,
                  field: field.field.name,
                },
                auth
              );
            }}
            defaultValue={field.value}
            type="textarea"
          />

          <Icon
            path={regenStatus === "error" ? mdiExclamation : regenStatus === "success" ? mdiCheck : mdiRefresh}
            size={1.2}
            className={`${regenStatus === "IN_PROGRESS" ? "rotate text-primary" : ""} tc-text-muted cursor-pointer`}
            onClick={() => {
              regenerateField(field.id, auth);
            }}
          />
        </div>
      </div>
    </div>
  );
}
